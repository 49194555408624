import { get } from 'lodash';

export function tooltipRenderer(params) {
  const value = params.datum[params.yKey];
  const formatValue = value == null || isNaN(value)? '0': parseFloat(value).toFixed(0).toString()
  return {
    title: params.xValue,
    content: params.yName + ": " + formatValue,
  };
}


export function tooltipRendererGenerator({ title=null, contentLabel=null, contentValue=null, staticTitle=null, staticContentLabel=null, contentValueFormatter=null } = {}) {
  return params => {
    const value = contentValue == null? params.yKey : get(params, contentValue, null);
    let formatValue = null;
    if (contentValueFormatter != null && typeof contentValueFormatter === 'function') {
      formatValue = contentValueFormatter(value);
    } else {
      formatValue = value == null || isNaN(value)? '0': parseFloat(value).toFixed(0).toString();
    }
    const t = staticTitle != null? staticTitle : (title == null? params.xValue : get(params, title, null));
    const cLabel = staticContentLabel != null? staticContentLabel : (contentLabel == null? params.yName : get(params, contentLabel, null));
    return {
      title: t,
      content: `${cLabel}: ${formatValue}`
    };
  }
}