<template>
  <WidgetFrame @onResize="onResize" @onOrientationChange="onOrientationChange">
      <template v-slot:title>
        {{ $t('task.title') }}
      </template>
      <template v-slot:content>
        <div v-if="!canView('PROJECT', ['TASK'])" class="center-text">
          {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
        </div>
        <template v-else-if="!loaded">
        <div class='status-message'>{{ $t('widget_loading') }}</div>
      </template>
      <template v-else-if="noTasks">
        <div class='status-message'>{{ $t('task.no_tasks_in_project') }}.</div>
      </template>
      <template v-else>
        <ag-charts-vue v-if="loaded" :options="options"></ag-charts-vue>
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
  import {AgChartsVue} from 'ag-charts-vue';
  import WidgetFrame from "@/components/Dashboard/WidgetFrame";
  import settings from "@/_dashboardSettings";
  import cColors from "@/_chartColors";
  import { EventBus, createBody } from '@/helpers';
  import { tooltipRendererGenerator } from '@/helpers/ag-chart-tooltip-renderer';
  import { compositeService } from '@/services';
  
  export default {
    name: 'ProjectTasksWidget',
    components: {
      AgChartsVue,
      WidgetFrame
    },
    props: {
      project:    { type: Object, required: true },
      taskCount:  { type: Number, default: 0 }
    },
    watch: {
      tasks: function () {
        this.buildData();
      }
    },
    data() {
      return {
        orientation: null,
        width: 0,
        height: 0,
        loaded: false,
        noTasks: false,
        options: null,
        sourceData: [],
      };
    },
    created() {
      EventBus.$on('theme-change', () => {
        if (this.loaded) {
          this.buildChart();
        }
      });

      this.buildData();
    },
    beforeDestroy() {
      EventBus.$off('theme-change');
    },
    methods: {
      async buildData() {
        this.sourceData = [];
        const self = this;
        
        // Only consider 'Task' and 'Miletone' tasks
        const cmdList = [];
        let index = 0;

        const fields = [
          ["PROJECT.uuId"],
          [["=countValues(A,B)", ["PROJECT"],["TASK.progress"] ]]
        ];
        
      
        cmdList.push({
           "note":`dashboard tasks progress`
          ,"invoke" : "/api/query/match"
          ,"body"   :  createBody({ 
            start: 0,
            limit: -1, 
            timeout: 60,
            ksort: null,
            holder: this.project.uuId,
            ksortAgFunc: null,
            ksortType: null,
            order: null
        }, fields, null, 
         null, 
         false)
        });
         
        const responseData = await compositeService.exec(cmdList, true)
        .then(response => {
          return response.data.feedbackList;
        })
        .catch((e) => {
          return null;
        });
        
        let started = 0;
        if (responseData[0].fetch && responseData[0].fetch.length) {
          for (const key of Object.keys(responseData[0].fetch[0][1])) {
            if (key !== '0.0' && key !== '1.0') {
              started += responseData[0].fetch[0][1][key];
            }
          }
        }
        let completed = responseData[0].fetch && responseData[0].fetch.length && responseData[0].fetch[0][1]['1.0'] ? responseData[0].fetch[0][1]['1.0'] : 0;
        let notStarted = self.taskCount;
        
        this.sourceData.push({'state': this.$t('task.completed'), 'count': completed});
        this.sourceData.push({'state': this.$t('task.in_progress'), 'count': started});
        this.sourceData.push({'state': this.$t('task.not_started'), 'count': notStarted - started - completed});

        this.buildChart();
        this.loaded = true;
      },
      buildChart() {
        var total = this.sourceData.reduce((total, state) => total + parseInt(state.count), 0);
        this.options = settings.getPieChartOptions(this.orientation, this.width, this.height, this.sourceData.length);
        this.options.title.text = `${total} ${this.$t('task.title')}`;
        this.options.series[0].data = this.sourceData;
        this.options.series[0].calloutLabelKey = 'state';
        this.options.series[0].angleKey = 'count';
        this.options.series[0].tooltip = {
          enabled: true,
          renderer: tooltipRendererGenerator({ title: 'datum["state"]', staticContentLabel: 'Tasks', contentValue: 'datum.count' })
        }
        this.options.series[0].fills = [cColors.getThemeColor('status-green'), cColors.getThemeColor('status-red'), cColors.getThemeColor('status-orange')]

      },
      onOrientationChange({orientation, width, height}) {
        // console.log(this.$options.name + ": Orientation change");
        this.orientation = orientation;
        this.onResize({width, height});
      },
      onResize({width, height}) {
        // console.log(this.$options.name + ": W: " + width + " H: " + height);
        this.width = width;
        this.height = height;
        if (this.loaded) {
          this.buildChart();
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .center-text {
    text-align: center;
    margin: auto;
  }
</style>